import type { CLSMetric, FCPMetric, FIDMetric, LCPMetric, TTFBMetric } from "web-vitals";

const reportWebVitals = (
  onPerfEntry?: (metric: CLSMetric | FCPMetric | FIDMetric | LCPMetric | TTFBMetric) => void,
): void => {
  if (onPerfEntry && typeof onPerfEntry === "function") {
    void import("web-vitals").then(({ onCLS, onFID, onFCP, onLCP, onTTFB }) => {
      onCLS(onPerfEntry);
      onFID(onPerfEntry);
      onFCP(onPerfEntry);
      onLCP(onPerfEntry);
      onTTFB(onPerfEntry);
    });
  }
};

export default reportWebVitals;
