import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.scss";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./app/store";
import { ToastContainer } from "react-toastify";
import { BrandProvider } from "./context/BrandContext";
import { Helmet, HelmetProvider } from "react-helmet-async";

const container = document.getElementById("root")!;
const root = createRoot(container);

const importBuildTarget = () => {
  const brandName = process.env.REACT_APP_BRAND || "bitcashier";
  import(`./brands/${brandName}/styles/main.scss`);
  return import(`./brands/${brandName}/config`);
};

importBuildTarget().then((brandConfig) => {
  const currentBrand = brandConfig.default;

  return root.render(
    <HelmetProvider>
      <BrowserRouter>
        <Provider store={store}>
          <BrandProvider initialConfig={currentBrand}>
            <div className='app-wrapper'>
              <Helmet>
                <title>{`${currentBrand.title} Payment`}</title>
                <link
                  id='favicon'
                  rel='icon'
                  href={`/favicons/${currentBrand.favicon}`}
                  type='image/x-icon'
                />
              </Helmet>
              <App />
              <ToastContainer hideProgressBar={true} />
            </div>
          </BrandProvider>
        </Provider>
      </BrowserRouter>
    </HelmetProvider>,
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
