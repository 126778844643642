import React, { createContext, ReactNode, useContext } from "react";

type KeyValueBrandType = {
  [key: string]: string;
};

export type BrandContextType = {
  images: KeyValueBrandType;
  title?: string;
  favicon?: string;
};

type BrandContextValue = {
  brandConfig: BrandContextType;
};

const BrandContext = createContext<BrandContextValue>({
  brandConfig: { images: {} },
});

type BrandProviderProps = {
  children: ReactNode;
  initialConfig?: BrandContextType;
};

export const BrandProvider = ({ children, initialConfig = { images: {} } }: BrandProviderProps) => {
  const brandConfig = initialConfig;

  return <BrandContext.Provider value={{ brandConfig }}>{children}</BrandContext.Provider>;
};

export const useBrand = () => useContext(BrandContext);
