import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { loadInvoiceSuccess, selectInvoice } from "./invoiceStore";
import { getInvoiceDetails } from "../../api/api";
import moment from "moment";

import Loader from "./components/loader/Loader";
import { PaymentStatusEnum } from "./components/paymentStatus/PaymentStatus";

import "./Invoice.scss";
import { from } from "rxjs";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { InvoiceModel } from "model/Invoice";
import PaymentAssets from "./components/paymentAssets/PaymentAssets";
import PaymentMethod from "./components/paymentMethod/PaymentMethod";
import PaymentPay from "./components/paymentPay/PaymentPay";
import NotFound from "../notFound/NotFound";
import { checkInvoiceStatus } from "helpers/invoices";
import { useBrand } from "../../context/BrandContext";

export enum PaymentStepEnum {
  STATE_CHOOSE_ASSET = "STATE_CHOOSE_ASSET",
  STATE_CHOOSE_METHOD = "STATE_CHOOSE_METHOD",
  STATE_PAY = "STATE_PAY",
}

function Invoice() {
  const [paymentStep, setPaymentStep] = useState(PaymentStepEnum.STATE_CHOOSE_ASSET);

  const [currentStatusCode, setCurrentStatusCode] = useState<PaymentStatusEnum | null>(null);

  const [isLoading, setIsLoading] = useState(true);

  const [error, setError] = useState(false);

  const [shouldInitialize, setShouldInitialize] = useState(true);

  const selectedInvoice = useAppSelector(selectInvoice);

  const dispatch = useAppDispatch();

  const { brandConfig } = useBrand();

  let { id } = useParams();

  useEffect(() => {
    from(getInvoiceDetails(id)).subscribe(
      (res) => {
        const invoice = res.data as InvoiceModel;
        dispatch(loadInvoiceSuccess(invoice));
        verifyStatus(invoice);
        verifyPaymentStep(invoice);
        setIsLoading(false);
      },
      () => {
        setIsLoading(false);
        setError(true);
      },
    );
  }, []);

  const onNextStep = (step: PaymentStepEnum): void => {
    setPaymentStep(step);
  };

  const renderPaymentStep = useMemo(() => {
    switch (paymentStep) {
      case PaymentStepEnum.STATE_CHOOSE_METHOD:
        return <PaymentMethod onNextStep={onNextStep}></PaymentMethod>;
      case PaymentStepEnum.STATE_CHOOSE_ASSET:
        return <PaymentAssets onNextStep={onNextStep}></PaymentAssets>;
      default:
        return (
          <PaymentPay
            shouldInitialize={shouldInitialize}
            currentStatusCode={currentStatusCode}
          ></PaymentPay>
        );
    }
  }, [paymentStep]);

  const verifyPaymentStep = (invoice: InvoiceModel): void => {
    const paymentOrders = invoice.paymentOrders;

    if (paymentOrders.length > 0) {
      const lastPaymentOrder = paymentOrders.at(-1);

      if (lastPaymentOrder) {
        const isBefore = isTimeExpired(lastPaymentOrder.expiresAt);

        if (isBefore) {
          setPaymentStep(PaymentStepEnum.STATE_PAY);

          setShouldInitialize(false);
          return;
        }
      }
    }
  };

  const verifyStatus = (invoice: InvoiceModel): void => {
    const invoiceStatus = checkInvoiceStatus(invoice);

    if (
      invoiceStatus !== PaymentStatusEnum.STATUS_TO_PROCESS &&
      invoiceStatus !== PaymentStatusEnum.STATUS_EXPIRED
    ) {
      setCurrentStatusCode(invoiceStatus);
      setPaymentStep(PaymentStepEnum.STATE_PAY);
      setShouldInitialize(false);
    }
  };

  const isTimeExpired = (expiresAt: string): boolean =>
    moment.utc().isBefore(moment(expiresAt).utc());

  return isLoading ? (
    <Loader></Loader>
  ) : !error ? (
    <div className='invoice'>
      <div className='invoice__logo'>
        <img src={brandConfig.images.logo} alt='logo' />
      </div>
      <div className='invoice__data'>
        <span className='invoice__title'>{selectedInvoice.title}</span>
        {selectedInvoice.description && (
          <span className='invoice__description'>{selectedInvoice.description}</span>
        )}
        <span className='invoice__amount'>
          Amount: {selectedInvoice.amount} {selectedInvoice.asset}
        </span>
      </div>
      {renderPaymentStep}
    </div>
  ) : (
    <NotFound />
  );
}

export default Invoice;
